<template>
  <VCard
    :loading="loading"
    :disabled="loading"
    :elevation="0"
  >
    <VRow class="mb-6">
      <VCol
        cols="12"
      >
        <h1 class="tt-text-headline-1">
          {{ normalizedTitle }}
        </h1>
      </VCol>
    </VRow>

    <VForm
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <VRow
        align="start"
      >
        <VCol
          cols="12"
        >
          <TTTextField
            label="Название команды"
            placeholder="Общество с ограниченной ответственностью «Ромашка»"
            :value="entity.name"
            large
            :rules="nameRules"
            :disabled="isReadOnly('name')"
            hide-details="auto"
            @input="$emit('update:name', $event)"
          />
        </VCol>
      </VRow>

      <VRow
        align="start"
      >
        <VCol
          cols="12"
        >
          <TTTextField
            label="Отображаемое название"
            placeholder="Ромашка"
            persistent-hint
            hint="Это название пользователи увидят в интерфейсе"
            large
            :value="entity.displayName"
            :disabled="isReadOnly('displayName')"
            @input="$emit('update:displayName', $event)"
          />
        </VCol>
      </VRow>

      <VRow
        align="start"
      >
        <VCol
          cols="6"
        >
          <TTTextField
            label="Тип"
            placeholder="Укажите тип"
            large
            hide-details="auto"
            :rules="typeRules"
            :disabled="isReadOnly('teamType')"
            :value="entity.teamType"
            @input="$emit('update:teamType', $event)"
          />
        </VCol>

        <VCol
          cols="6"
        >
          <TTTextField
            label="Внешний идентификатор"
            placeholder="Укажите ID"
            large
            hide-details="auto"
            :value="entity.externalId"
            :disabled="isReadOnly('externalId')"
            @input="$emit('update:externalId', $event)"
          />
        </VCol>
      </VRow>
      <VRow
        align="start"
      >
        <VCol
          cols="12"
        >
          <TTCheckbox
            v-model="parentTeamCheckbox"
            class="mt-0 pt-0"
            label="Дочерняя команда"
            hide-details
          />
        </VCol>
      </VRow>

      <VRow
        v-if="parentTeamCheckbox"
        align="center"
      >
        <VCol
          cols="12"
        >
          <TTAutocomplete
            :value="entity.parentId"
            :items="teams"
            :disabled="isReadOnly('parentId')"
            item-text="name"
            item-value="id"
            large
            label="Родительская команда"
            hide-details="auto"
            placeholder="Введите название"
            @input="$emit('update:parentId', $event)"
          >
            <template #append>
              <VIcon>
                fal fa-search
              </VIcon>
            </template>
          </TTAutocomplete>
        </VCol>
      </VRow>
    </VForm>

    <VRow
      class="mt-5"
      align="center"
    >
      <VCol cols="12">
        <TTBtn
          color="tt-secondary"
          large
          @click="handleCancel"
        >
          Отмена
        </TTBtn>

        <TTBtn
          class="ml-4"
          color="primary"
          large
          :disabled="!valid"
          @click="handleSubmit"
        >
          {{ normalizedSubmit }}
        </TTBtn>
      </VCol>
    </VRow>
  </VCard>
</template>

<script>

export default {
  name: 'TeamForm',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      default: false,
    },

    teams: {
      type: Array,
      required: true,
    },

    readOnly: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      parentTeamCheckbox: false,
      valid: true,
      nameRules: [
        (v) => !!v || 'Введите имя',
        (v) => v?.length <= 700 || 'Имя не должно быть длиннее 700 символов',
      ],
      // FIXME: форма редактирования поломана из-за этого обязательного правила, там это поле read-only и может быть
      // пустым
      // typeRules: [
      //   (v) => !!v || 'Введите тип',
      //   (v) => v?.length <= 700 || 'Тип не должен быть длиннее 700 символов',
      // ],
    };
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание команды';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование команды';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Создать команду';

      if (this.entity.id !== null) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  watch: {
    'entity.parentId': {
      handler(newVal) {
        if (newVal) {
          this.parentTeamCheckbox = true;
        }
      },
    },

    parentTeamCheckbox(newVal) {
      if (!newVal) {
        this.$emit('update:parentId', null);
      }
    },
  },

  methods: {
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.$emit('submit', this.entity);
      }
    },

    isReadOnly(key) {
      return this.readOnly.includes(key);
    },
  },
};
</script>
