<template>
  <TTView>
    <VRow>
      <VCol>
        <TeamForm
          :entity="team"
          :loading="loading"
          :teams="teams"
          :read-only="['teamType']"
          @update:name="team.name = $event"
          @update:displayName="team.displayName = $event"
          @update:teamType="team.teamType = $event"
          @update:externalId="team.externalId = $event"
          @update:parentId="team.parentId = $event"
          @submit="handleEditTeam"
          @cancel="handleCancel"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import _ from 'lodash';
import TeamForm from '../../../components/v2/TeamForm.vue';

const editTeamDTO = {
  id: null,
  name: null,
  displayName: null,
  teamType: null,
  companyId: null,
  externalId: null,
  // TODO: hmmmm????
  parentId: null,
};

export default {
  name: 'TeamEditView',

  components: { TeamForm },

  inject: ['Names'],

  props: {
    accountId: {
      type: String,
      required: true,
    },

    companyId: {
      type: String,
      required: true,
    },

    teamId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      team: _.cloneDeep(editTeamDTO),
      teams: [],
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const app = new this.$di.ddd.Orgstructure();

        const [[team], [teams]] = await Promise.all([
          app.services.team.getTeam({ id: this.teamId, companyId: this.companyId }),
          app.services.team.getTeams({ companyId: this.companyId }),
        ]);

        this.team = team;
        this.teams = teams;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleEditTeam() {
      try {
        this.loading = true;
        const dto = _.cloneDeep({ ...this.team, companyId: this.companyId });
        const app = new this.$di.ddd.Orgstructure();

        // HACK: so dirty :"(
        // Нужно чтобы команда находилась в коллекции для обновления
        // TODO: подумать как быть в таких случаях, всякие repository.find ...
        const team = app.factories.team.create(this.team);
        app.repositories.team.collection.push(team);

        await app.services.team.editTeam(dto);

        this.$di.notify.snackSuccess('Команда успешно обновлена');

        this.$router.push({ name: this.Names.R_ACCOUNT_V2_COMPANY_TEAM_VIEW });
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
